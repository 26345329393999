import React, { useState, useEffect } from "react";

import { NavLink } from "react-router-dom";
import companyLogo from "../../assets/logos/companyMain.jpeg";

const Header = () => {
  const toggleHeaderSlidebar = () => {
    const toggleHeader = document.getElementById("nav");
    toggleHeader.classList.toggle("show");

    const showheadercontenttoggleline1 = document.getElementById(
      "animateline1"
    );
    showheadercontenttoggleline1.classList.toggle("animate1");
    const showheadercontenttoggleline2 = document.getElementById(
      "animateline2"
    );
    showheadercontenttoggleline2.classList.toggle("animate2");
    const showheadercontenttoggleline3 = document.getElementById(
      "animateline3"
    );
    showheadercontenttoggleline3.classList.toggle("animate3");

    const toggleBodyScroll = document.getElementById("body");
    if (toggleBodyScroll.className === "enablescroll") {
      toggleBodyScroll.className = "disablescroll";
    } else {
      toggleBodyScroll.className = "enablescroll";
    }
  };
  const eventHandler = (e) => {
    const bodyPosition = document
      .getElementById("body")
      .getBoundingClientRect();

    if (bodyPosition.top < -50) {
      document.getElementById("logoheader").style.opacity = "0";
      document.getElementById("logotext").style.opacity = "0";
      // document.getElementById("companylogotruck").className = "onlyLogoTruck"
      // "translateY(-34px) translateX(-250px)";
    } else {
      document.getElementById("logoheader").style.opacity = "1";
      document.getElementById("logotext").style.opacity = "1";
      // document.getElementById("companylogotruck").style.transform =
      //   "translateY(-34px) translateX(0px)";
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", eventHandler);
    return () => {
      window.removeEventListener("scroll", eventHandler);
    };
  });
  return (
    <header>
      <div className="container">
        <div id="logo">
          <NavLink to="/" style={{ color: "black", textDecoration: "none" }}>
            <h1 id="logoheader">FREIGHTAGE LLC</h1>
            <p id="logotext">Logistics Simplified</p>
            <img id="companylogotruck" src={companyLogo} />
          </NavLink>
          <div
            onClick={() => {
              toggleHeaderSlidebar();
            }}
            id="bar"
          >
            <div className="line" id="animateline1"></div>
            <div className="line" id="animateline2"></div>
            <div className="line" id="animateline3"></div>
          </div>
        </div>
        <nav id="nav">
          <div id="bar">
            <div style={{ borderColor: "white" }} className="line"></div>
            <div style={{ borderColor: "white" }} className="line"></div>
            <div style={{ borderColor: "white" }} className="line"></div>
          </div>
          <ul>
            {["home", "news", "careers"].map((data, index) => {
              return (
                <li key={index}>
                  <NavLink
                    onClick={() => {
                      // const toggleBodyScroll = document.getElementById("body");
                      // toggleBodyScroll.classList.toggle("disablescroll");
                    }}
                    // style={{ color: "rgba(0,0,0,0.6)" }}
                    to={data === "home" ? "/" : "/" + data}
                  >
                    {data}
                  </NavLink>
                  {/* <div className="hr" /> */}
                </li>
              );
            })}
          </ul>
          <div id="contact-tab">
            <NavLink
              to="/contact"
              style={{
                color: "#333333",
                fontWeight: "600",

                textDecoration: "none",
                fontSize: 14,
                // color: "rgba(0,0,0,0.6)",
              }}
              onClick={() => {
                const toggleBodyScroll = document.getElementById("body");
                toggleBodyScroll.classList.toggle("disablescroll");
              }}
            >
              contact Us
            </NavLink>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
