import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { initializeApp } from "firebase";

const webFirebaseConfig = {
  apiKey: "AIzaSyB5Kh6l9TZsg3rz7Dhsyi2hKUxvid2OjVY",
  authDomain: "projectcargo-56aeb.firebaseapp.com",
  databaseURL: "https://projectcargo-56aeb.firebaseio.com",
  projectId: "projectcargo-56aeb",
  storageBucket: "projectcargo-56aeb.appspot.com",
  messagingSenderId: "194020311810",
  appId: "1:194020311810:web:cf00a47f58224ae5a2f81b",
};
initializeApp(webFirebaseConfig);

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
