import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import MessageForm from "../Custom/MessageForm";
import CustomMap from "../Custom/CustomMap";
import { GoogleApiWrapper } from "google-maps-react";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";

function Contact(props) {
  return (
    <React.Fragment>
      <Header />
      <section id="contactpagesection">
        <div className="container">
          <div id="contactleft">
            <p id="contactpageheader">Connect With Us</p>
            <div>
              <h4 style={{ marginBottom: 0 }}>Leave a Message</h4>
              <MessageForm />
            </div>
            <div id="contactleftbottom">
              <div className="contactleftbottomboxes">
                <h4 style={{ marginBottom: 0 }}>Our Office Address</h4>
                <p>
                  151-05B 132nd Ave
                  <br />
                  Jamaica, NY 11434
                </p>
              </div>
              <div className="contactleftbottomboxes">
                <h4 style={{ marginBottom: 0 }}>Our Remote Office</h4>
                <p>
                  422 S Broadway
                  <br />
                  Hicksville, NY 11801
                </p>
              </div>
              <div className="contactleftbottomboxes">
                <h4 style={{ marginBottom: 0 }}>Call Us</h4>
                <p>
                  <a href="tel:+1718525650">(718) 525-6500</a>
                  <br />
                  <a href="tel:+17189620894">(718) 962-0894</a>
                  <br />
                  <a href="tel:+17185256502">(718) 525-6502</a>
                  <br />
                  <a href="tel:+17329105734">(732) 910-5734</a>
                  <br />
                  <a href="tel:+16467551265">(646) 755-1265</a>
                </p>
              </div>
              <div className="contactleftbottomboxes">
                <h4 style={{ marginBottom: 0 }}>Follow Us</h4>
                <ul>
                  {[
                    { component: <FaFacebookF color="black" />, link: "" },
                    { component: <FaTwitter color="black" />, link: "" },
                    { component: <FaLinkedinIn color="black" />, link: "" },
                  ].map((val, index) => (
                    <li key={index.toString()}>
                      <a target="_blank" href={val.link}>
                        {val.component}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="contactleftbottomboxes">
                <h4 style={{ marginBottom: 0 }}>Email Us</h4>
                <p>
                  <a
                    onClick={() => {
                      window.location.href = "mailto:freightage@freightagellc.com";
                    }}
                  >
                    freightage@freightagellc.com
                  </a>
                  <br />
                  <a
                    onClick={() => {
                      window.location.href = "mailto:freightagellc@gmail.com";
                    }}
                  >
                    freightagellc@gmail.com
                  </a>
                  <br />
                  <a
                    onClick={() => {
                      window.location.href = "mailto:babar@freightagellc.com";
                    }}
                  >
                    babar@freightagellc.com
                  </a>
                  <br />
                  <a
                    onClick={() => {
                      window.location.href = "mailto:sayyamshabbir@freightagellc.com";
                    }}
                  >
                    sayyamshabbir@freightagellc.com
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div id="contactright">
            <CustomMap google={props.google} />
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
}

export default GoogleApiWrapper({
  // apiKey: "AIzaSyCphsGKM-aoSIo2aF5Q_ysYplj7oNz6IxY",
  apiKey: "AIzaSyCx_zMgfUX6pju8V3rw0O1QSm4T40ND4iY",
})(Contact);
