import React, { useState } from "react";
import { Form, Field, Formik } from "formik";
import * as yup from "yup";
import validator from "validator";
import { firestore } from "firebase";
import { Spinner, IssueIcon } from "evergreen-ui";
const messageValidationSchema = yup.object().shape({
  fullName: yup
    .string()
    .required("Name is required")
    .test(
      "fullName",
      "Enter full Name",
      (value) => /^[a-zA-Z ]+$/.test(value) === true
    ),
  phoneNumber: yup
    .string()
    .required("Phone Number is required")
    .test(
      "phonenumber",
      "Not a valid Phone Number",
      (value) => validator.isMobilePhone(value) === true
    ),
  email: yup.string().required().email(),
  companyName: yup
    .string()
    .max(20)
    // .nullable()
    .test(
      "companyName",
      "Company Name not found",
      (value) => /^[a-zA-Z ]+$/.test(value) === true
    ),
  message: yup
    .string()
    .required("Message is required, Atleast 15 letters")
    .min(15),
});

// const App = ({
//   values,
//   errors,
//   touched,
//   isSubmitting,
//   Loading,
//   setLoading,
//   handleSubmit,
// }) => {
//   return (
//     <Form>
//       {isSubmitting ? <Spinner /> : <button id="submit">Submit</button>}

//       <p> {isSubmitting ? "true" : "false"}</p>
//     </Form>
//   );
// };

const MessageForm = () => {
  const [loading, setLoading] = useState(false);
  return (
    <Formik
      initialValues={{
        fullName: "",
        phoneNumber: "",
        email: "",
        companyName: "",
        message: "",
      }}
      onSubmit={(values, actions) => {
        setLoading(true);
        firestore()
          .collection("freightagellcwebsiteusermessages")
          .add({
            newUserMessage: {
              fullName: values.fullName,
              phoneNumber: values.phoneNumber,
              email: values.email,
              companyName: "" | values.companyName,
              message: values.message,
            },
          })
          .then((res) => {
            alert("We received your message, Will Contact you shortly");
            actions.resetForm();
            setLoading(false);
          })
          .catch((err) => {
            alert(err);
            actions.resetForm();
            setLoading(false);
          });
      }}
      validationSchema={messageValidationSchema}
    >
      {(formikProps) => (
        <form id="messageform" onSubmit={formikProps.handleSubmit}>
          <Field
            className="App-form-input-field"
            name="fullName"
            type="text"
            placeholder={"Your Full Name"}
          ></Field>
          {formikProps.touched.fullName && formikProps.errors.fullName && (
            <p className="input-error-text">{formikProps.errors.fullName}</p>
          )}
          <Field
            className="App-form-input-field"
            name="phoneNumber"
            type="text"
            placeholder={"Phone Number"}
          ></Field>
          {formikProps.touched.phoneNumber &&
            formikProps.errors.phoneNumber && (
              <p className="input-error-text">
                {formikProps.errors.phoneNumber}
              </p>
            )}

          <Field
            className="App-form-input-field"
            name="email"
            type="text"
            placeholder={"Email"}
          ></Field>
          {formikProps.touched.email && formikProps.errors.email && (
            <p className="input-error-text">{formikProps.errors.email}</p>
          )}

          <Field
            className="App-form-input-field"
            name="companyName"
            type="text"
            placeholder={"Company Name"}
          ></Field>
          {formikProps.touched.companyName &&
            formikProps.errors.companyName && (
              <p className="input-error-text">
                {formikProps.errors.companyName}
              </p>
            )}

          <Field
            className="App-form-input-field"
            name="message"
            type="text"
            placeholder={"Your Message"}
          ></Field>
          {formikProps.touched.message && formikProps.errors.message && (
            <p className="input-error-text">{formikProps.errors.message}</p>
          )}
          {loading ? <Spinner /> : <button id="submit">Leave a Message</button>}
        </form>
      )}
    </Formik>
  );
};
// withFormik({
//   mapPropsToValues({ fullName, phoneNumber, companyName, message }) {
//     return {
//       fullName: fullName || "",
//       phoneNumber: phoneNumber || "",
//       companyName: companyName || "",
//       message: message || "",
//     };
//   },
//   validationSchema: messageValidationSchema,
//   handleSubmit(values, { resetForm, setSubmitting, setValues }) {
//     alert(JSON.stringify(values));
//
//     setTimeout(() => {
//       setSubmitting(false);
//       resetForm();
//     }, 2000);
//   },
// })(App);

export default MessageForm;
