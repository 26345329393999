import React, { useContext } from "react";
import logo from "./logo.svg";
import News from "./components/News";
import Careers from "./components/Careers";
import About from "./components/About";
import { Switch, Route, __RouterContext, NavLink } from "react-router-dom";
import { useTransition, animated } from "react-spring";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import MessageForm from "./components/Custom/MessageForm";
import Contact from "./components/Contact";
import Home from "./components/Home";

function Page404() {
  return (
    <div>
      <h2 style={{ margin: "15vh" }}>
        Believe me
        {<br style={{ margin: "10%" }} />}
        Im working on it...
      </h2>
    </div>
  );
}

function App() {
  const { location } = useContext(__RouterContext);

  const transition = useTransition(location, (location) => location.pathname, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 300, delay: 20 },
    onDestroyed: () => {
      document.getElementById("body").className = "enablescroll";
      window.scrollTo(0, 0);
    },
  });
  return (
    <div id="App">
      {transition.map(({ item, props, key }) => (
        <animated.div style={props} key={key}>
          <Switch location={item}>
            <Route exact path="/" component={Home} />
            <Route exact path="/news" component={News} />
            <Route exact path="/careers" component={Careers} />
            <Route exact path="/about" component={About} />
            <Route exact path="/contact" component={Contact} />
            <Route component={Page404} />
          </Switch>
        </animated.div>
      ))}
    </div>
  );
}

export default App;
