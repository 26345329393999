import React, { Component } from "react";
import Header from "../Header";
import Footer from "../Footer";
class Careers extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Header></Header>
        <div className="container">
          <section
            style={{
              height: 1080,
              textAlign: "center",
              fontSize: 30,
              marginTop: 178,
              color: "#4a4a4a",
            }}
          >
            <h4>No Jobs Open</h4>
          </section>
        </div>
        <Footer> </Footer>
      </React.Fragment>
    );
  }
}

export default Careers;
