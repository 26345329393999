import React, { useState } from "react";
// import "../../App.css";
import socialMediaIcon from "../../assets/images/social-media-icon.png";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
const Footer = () => {
  return (
    <React.Fragment>
      <footer className="App-footer ">
        <div className="App-footer-left">
          <p>
            Community | 718-525-6500 <br />
            151-05B 132nd Ave, Jamaica, NY 11434
          </p>
        </div>
        <div className="App-footer-right">
          <div>
            <ul id="footersocialicons">
              {[
                {
                  component: <FaFacebookF size="15" color="black" />,
                  link: "",
                },
                { component: <FaTwitter color="black" size="15" />, link: "" },
                {
                  component: <FaLinkedinIn color="black" size="15" />,
                  link: "",
                },
              ].map((val, index) => (
                <li key={index.toString()}>
                  <a target="_blank" href={val.link}>
                    {val.component}
                  </a>
                </li>
              ))}
            </ul>
            <p>Copyright © 2023 - All Rights Reserved</p>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
