import React, { Component, useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";

import socialIcons from "../../assets/images/social-media-icon.png";
import news1 from "../../assets/images/4.jpg";
import news2 from "../../assets/images/news2.jpeg";
import news3 from "../../assets/images/news3.jpeg";
import news4 from "../../assets/images/news4.jpeg";

const News = () => {
  const expandLandingListener = () => {
    return null;
  };

  useEffect(() => {
    window.addEventListener("scroll", expandLandingListener, true);
    return () => {
      window.removeEventListener("scroll", expandLandingListener, true);
    };
  });
  return (
    <React.Fragment>
      <Header></Header>
      <div id="newspage">
        <div className="container">
          <article id="newsmainarticle">
            <div id="newsmain">
              {/* <img src={activeNewsImage}></img>
          <div id="newscontent">
            <em>{activeNewsHeadline}</em>
            <p id="date">June, 2018</p>
            <p>
              Opened a new location in Suffolk County to better serve Long
              Island residents. September 14, 2018 will commemorate the 24th
              year of Robin A Gray Esq., admittance to the NY State Bar.
            </p>
            <p>
              Opened a new location in Suffolk County to better serve Long
              Island residents. September 14, 2018 will commemorate the 24th
              year of Robin A Gray Esq., orem to the NY State Bar.
            </p>
            <p>
              Opened a new location in Suffolk County to better serve Long
              Island residents. September 14, 2018 will commemorate the 24th
              year of Robin A Gray Esq., admittance o the NY State Bar.
            </p>
          </div> */}
              {/* <iframe
            width="100%"
            height="914"
            src="https://rss.app/embed/v1/UmVQaQ4qnJsYFLcz"
            frameBorder="0"
          ></iframe> */}
              <iframe
                width="100%"
                height="974"
                src="https://rss.app/embed/v1/N1IEwXjWQPDcGs7n"
                frameBorder="0"
              ></iframe>
            </div>

            <section id="share">
              {/* <p>Share this</p>
          <img src={socialIcons}></img> */}
            </section>
          </article>
          <aside id="newslist">
            {
              // [
              //   {
              //     newsImg: news2,
              //     newsHeadline:
              //       "Real Estate prices in Long Island reaches lowest ever",
              //   },
              //   {
              //     newsImg: news3,
              //     newsHeadline: "Stocks freightage strikes",
              //   },
              //   {
              //     newsImg: news4,
              //     newsHeadline: "Freightage increasing the hourly rates of tuckers",
              //   },
              // ]
              // newsList.map((data, index) => {
              //   return (
              //     <div
              //       key={index}
              //       id="newsbox"
              //       onClick={() => {
              //         setActiveNewsImage(data.newsImg);
              //         setActiveNewsHeadline(data.newsHeadline);
              //         document.body.scrollTop = 0;
              //         document.documentElement.scrollTop = 0;
              //       }}
              //     >
              //       {/* <img src={data.newsImg}></img> */}
              //       {/* {`${data.newsDescription}`}
              //       {console.error(data.newsDescription)}
              //       <p>{data.newsHeadline}</p> */}
              //     </div>
              //   );
              // })
            }
            {/* <iframe
          width="100%"
          height="100%"
          src="https://rss.app/embed/v1/pIAjw8TepOf8AuAb"
          frameBorder="0"
        ></iframe> */}
            <iframe
              width="100%"
              height="100%"
              src="https://rss.app/embed/v1/hCwvF0biqQhcMiWP"
              frameBorder="0"
            ></iframe>
          </aside>
          {/* <NewsComponent></NewsComponent> */}
        </div>
      </div>

      <Footer> </Footer>
    </React.Fragment>
  );
};

const NewsComponent = () => {
  const [activeNewsImage, setActiveNewsImage] = useState(news1);
  const [activeNewsHeadline, setActiveNewsHeadline] = useState(
    "New York State passed new law for DG clearance "
  );
  const [newsList, setnewsList] = useState([]);
  useEffect(() => {
    fetch("https://widget.rss.app/v1/list.js").then((res) => {
      res.text().then((xml) => {
        // let parser = new DOMParser();

        // let parsedXml = parser.parseFromString(xml, "application/xml");

        // let descriptions = parsedXml.querySelectorAll("description");

        // descriptions.forEach((element) => {
        //   console.error(element.firstChild.nodeType);
        // });
        console.error(xml);
      });
    });
  }, []);
  return (
    <React.Fragment>
      <article id="newsmainarticle">
        <div id="newsmain">
          {/* <img src={activeNewsImage}></img>
          <div id="newscontent">
            <em>{activeNewsHeadline}</em>
            <p id="date">June, 2018</p>
            <p>
              Opened a new location in Suffolk County to better serve Long
              Island residents. September 14, 2018 will commemorate the 24th
              year of Robin A Gray Esq., admittance to the NY State Bar.
            </p>
            <p>
              Opened a new location in Suffolk County to better serve Long
              Island residents. September 14, 2018 will commemorate the 24th
              year of Robin A Gray Esq., orem to the NY State Bar.
            </p>
            <p>
              Opened a new location in Suffolk County to better serve Long
              Island residents. September 14, 2018 will commemorate the 24th
              year of Robin A Gray Esq., admittance o the NY State Bar.
            </p>
          </div> */}
          {/* <iframe
            width="100%"
            height="914"
            src="https://rss.app/embed/v1/UmVQaQ4qnJsYFLcz"
            frameBorder="0"
          ></iframe> */}
          <iframe
            width="100%"
            height="974"
            src="https://rss.app/embed/v1/N1IEwXjWQPDcGs7n"
            frameBorder="0"
          ></iframe>
        </div>

        <section id="share">
          {/* <p>Share this</p>
          <img src={socialIcons}></img> */}
        </section>
      </article>
      <aside id="newslist">
        {
          // [
          //   {
          //     newsImg: news2,
          //     newsHeadline:
          //       "Real Estate prices in Long Island reaches lowest ever",
          //   },
          //   {
          //     newsImg: news3,
          //     newsHeadline: "Stocks freightage strikes",
          //   },
          //   {
          //     newsImg: news4,
          //     newsHeadline: "Freightage increasing the hourly rates of tuckers",
          //   },
          // ]
          // newsList.map((data, index) => {
          //   return (
          //     <div
          //       key={index}
          //       id="newsbox"
          //       onClick={() => {
          //         setActiveNewsImage(data.newsImg);
          //         setActiveNewsHeadline(data.newsHeadline);
          //         document.body.scrollTop = 0;
          //         document.documentElement.scrollTop = 0;
          //       }}
          //     >
          //       {/* <img src={data.newsImg}></img> */}
          //       {/* {`${data.newsDescription}`}
          //       {console.error(data.newsDescription)}
          //       <p>{data.newsHeadline}</p> */}
          //     </div>
          //   );
          // })
        }
        {/* <iframe
          width="100%"
          height="100%"
          src="https://rss.app/embed/v1/pIAjw8TepOf8AuAb"
          frameBorder="0"
        ></iframe> */}
        <iframe
          width="100%"
          height="100%"
          src="https://rss.app/embed/v1/hCwvF0biqQhcMiWP"
          frameBorder="0"
        ></iframe>
      </aside>
    </React.Fragment>
  );
};

export default News;
