import React, { useState, Component, useEffect, useRef } from "react";
import slideShowImg from "../../assets/images/slide01.jpg";
import slideShowImg4 from "../../assets/images/440d90888f85fd1da96df9439f9dbe59.jpg";

import slideShowImg3 from "../../assets/images/slideshow.jpeg";
import slideShowImg2 from "../../assets/images/mobileviewimage1.jpg";
import { GrFormClose } from "react-icons/gr";
import { FaDirections } from "react-icons/fa";
import truckImage from "../../assets/icons/markerMap.png";
import comapnyLogo1 from "../../assets/logos/company1@2x.png";
import comapnyLogo2 from "../../assets/logos/company2.jpeg";
import comapnyLogo3 from "../../assets/logos/company3.png";
import companyLogo4 from "../../assets/logos/company4.png";
import "../../App.css";
import {
  Map,
  InfoWindow,
  Marker,
  GoogleApiWrapper,
  Polygon,
  Polyline,
} from "google-maps-react";
import App from "../../App";
import { white } from "ansi-colors";
import { Transition, animated } from "react-spring/renderprops";
import { CornerDialog, defaultTheme, TruckIcon } from "evergreen-ui";
import colors from "../../assets/theme/colors";
import customstyles from "../../assets/map/googlemap";
import { Form, Field } from "formik";
import * as yup from "yup";
import validator from "validator";
import { firestore } from "firebase";
import { Spinner } from "evergreen-ui";
import { NavLink } from "react-router-dom";
import MessageForm from "../Custom/MessageForm";
import AnimatedSlideShowHeaderText from "../Custom/MessageForm/AnimatedSlideShowHeaderText";
import CustomMap from "../Custom/CustomMap";
import Header from "../Header";
import Footer from "../Footer";
import Banner from "../Banner";

const imagesForSLideShow = [
  slideShowImg,
  slideShowImg2,
  slideShowImg3,
  slideShowImg4,
];

const Home = (props) => {
  // var directionDisplay = new window.google.maps.DirectionsRenderer();
  // var directionService = new window.google.maps.DirectionsService();
  const [index, setIndex] = useState(0);
  const [moveGlass, setMoveGlass] = useState(false);
  const [moveGlassIteration, setMoveGlassIteration] = useState(0);
  const [slideImageIteration, setSlideImageIteration] = useState(0);
  const [glassTransition, resetGlassTransition] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [aboutAnimated, setAboutAnimated] = useState(false);
  const [servicesAnimated, setServicesAnimated] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [showInfoWindow, setShowInfoWindow] = useState(null);
  const [hideMessageModal, setHideMessageModal] = useState(false);

  const [toggle, set] = useState(true);

  const officeMarker = useRef();

  const messageValidationSchema = yup.object().shape({
    fullName: yup
      .string()
      .required()
      .test(
        "name",
        "Enter full Name",
        (value) => /^[a-zA-Z ]+$/.test(value) === true
      ),
    phoneNumber: yup
      .string()
      .required()
      .test(
        "phonenumber",
        "Not a valid Phone Number",
        (value) => validator.isMobilePhone(value) === true
      ),
    companyName: yup
      .string()
      .max(20)
      // .nullable()
      .test(
        "companyname",
        "error",
        (value) => /^[a-zA-Z ]+$/.test(value) === true
      ),
    message: yup.string().required().min(15),
  });

  // const formik = useFormik({
  //   initialValues: {
  //     fullName: "",
  //     phoneNumber: "",
  //     companyName: "",
  //     message: "",
  //   },
  //   validationSchema: messageValidationSchema,
  //   onSubmit: (values, actions) => {
  //     alert(JSON.stringify(values));
  //     // setLoading(true);
  //     // firestore()
  //     //   .collection("freightagellcwebsiteusermessages")
  //     //   .add({
  //     //     newUserMessage: {
  //     //       fullName: values.fullName,
  //     //       phoneNumber: values.phoneNumber,
  //     //       companyName: "" | values.companyName,
  //     //       message: values.message,
  //     //     },
  //     //   })
  //     //   .then((res) => {
  //     //     alert("We received your message, Will Contact you shortly");
  //     //     setLoading(false);
  //     //   })
  //     //   .catch((err) => {
  //     //     alert(err);
  //     //     setLoading(false);
  //     //   });
  //   },
  // });

  const starthomeaboutanimation = () => {
    const aboutoutsidetoggle = document.getElementById("asideoneoutside");
    aboutoutsidetoggle.className = "fullwidth";
    setTimeout(() => {
      const aboutoutsidetoggle = document.getElementById("asideoneoutside");
      aboutoutsidetoggle.classList.add("fade");
      const aboutinsideshowtoggle = document.getElementById("asideone");
      aboutinsideshowtoggle.classList.add("show");
      const aboutoneinsideshow = document.getElementById("asideoneinside");
      aboutoneinsideshow.classList.add("show");
      // const aboutshowcontenttoggle = document.getElementById("aboutcontent");
      // aboutshowcontenttoggle.classList.toggle("show");
    }, 1000);
  };

  const startServicesAnimation = () => {
    const servicesbar1expand = document.getElementById("sectionservicesbar1");
    servicesbar1expand.className = "expand";
    const servicesbar2expand = document.getElementById("sectionservicesbar2");
    servicesbar2expand.className = "expand";

    //service boxes shown with delay
    const servicebox1show = document.getElementById("servicetypebox1");
    servicebox1show.classList.add("show");
    const servicebox2show = document.getElementById("servicetypebox2");
    servicebox2show.classList.add("show");
    const servicebox3show = document.getElementById("servicetypebox3");
    servicebox3show.classList.add("show");
    const servicebox4show = document.getElementById("servicetypebox4");
    servicebox4show.classList.add("show");
  };

  const listener = (e) => {
    console.warn(
      (document.getElementById("sectionservices").getBoundingClientRect().top /
        window.innerHeight) *
        100
    );
    if (
      (-document.body.getBoundingClientRect().top / window.innerHeight) * 100 >
      10
    ) {
      if (!aboutAnimated) {
        starthomeaboutanimation();
        setAboutAnimated(true);
      }
    }
    if (
      (document.getElementById("sectionservices").getBoundingClientRect().top /
        window.innerHeight) *
        100 <
      50
    ) {
      if (!servicesAnimated) {
        startServicesAnimation();
        // setServicesAnimated(true);
      }
    }
  };

  const resizeListener = (e) => {
    setWindowSize(window.innerWidth);
  };
  useEffect(() => {
    const interval = setInterval(
      () => {
        // if (index === imagesForSLideShow.length - 1) {
        //   setIndex(0);
        // } else {
        // setIndex(index + 1);
        setMoveGlass(!moveGlass);
        // }
      },
      moveGlass ? 5000 : 300
    );

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
  });
  useEffect(() => {
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  });
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (slideImageIteration === 4) {
  //       setSlideImageIteration(0);
  //     } else {
  //       setSlideImageIteration(slideImageIteration + 1);
  //     }
  //   }, 12000);

  //   return () => clearInterval(interval);
  // }, [slideImageIteration]);

  const restart = () => {
    if (moveGlassIteration === 3) {
      setMoveGlassIteration(0);
      resetGlassTransition(true);
    } else {
      resetGlassTransition(false);
      setMoveGlassIteration(moveGlassIteration + 1);
    }
    setMoveGlass(!moveGlass);
  };

  return (
    <React.Fragment>
      <Header />
      {/* <Banner /> */}
      <div id="slideshow">
        <Transition
          unique={glassTransition}
          reset={glassTransition}
          native
          items={moveGlass}
          from={{
            width: (window.screen.width / 100) * 30,

            height: 800,
            backgroundColor: "black",
            position: "absolute",
            zIndex: 1,
            transform: `rotate(20deg)`,
            marginTop: -100,
            marginLeft: 0,
          }}
          enter={{
            width: window.screen.width + 800,

            height: 2000,

            backgroundColor: "rgba(0,0,0,0.36)",
            position: "absolute",
            zIndex: 1,
            transform: `rotate(20deg)`,
            marginTop: -600,

            marginLeft: (window.screen.width / 100) * 60,
          }}
          leave={{
            width: window.screen.width + 800,
            height: 2000,

            backgroundColor: "black",
            position: "absolute",
            zIndex: 1,
            transform: `rotate(20deg)`,
            marginTop: -2000,
            marginLeft: -(window.screen.width / 100) * 200,
          }}
          config={{ friction: 80, mass: 1, tension: 126 }}
          onDestroyed={() => {
            restart();
            if (moveGlass) {
              setTimeout(() => {
                if (slideImageIteration === 3) {
                  setSlideImageIteration(0);
                } else {
                  setSlideImageIteration(slideImageIteration + 1);
                }
              }, 300);
            }
          }}
        >
          {(show) =>
            show && ((props) => <animated.div style={props}></animated.div>)
          }
        </Transition>
        <div className="slideshowoverlay">
          <div id="slideshowoverlayleft" onClick={() => set((state) => !state)}>
            <div id="trails-text-wrapper">
              <AnimatedSlideShowHeaderText />
              {/* <p>Making Logistics Simplified</p> */}
            </div>
            <div id="slideshowbuttons">
              <NavLink to="/contact" className="slideshowbutton">
                <p style={{ fontWeight: "bold" }}>Hire Us</p>
              </NavLink>
              <NavLink to="/news" className="slideshowbutton">
                <p style={{ fontWeight: "bold" }}>Browse News Feeds</p>
              </NavLink>
            </div>
          </div>
          <div id="slideshowoverlayright"></div>
        </div>
        <div id="slideshowImage" alt="Image Loading"></div>
      </div>

      <section id="asideone">
        <div id="asideoneinside">
          <div className="container">
            <p id="aboutheader">About Us</p>
            <p id="aboutcontent">
              Freightage is a limited liability company that provides top-notch
              freight and transportation solutions.
              <br /> Is a company with TSA approved drivers and have substantial
              operating capacity
              <br /> in the Tri-State on major airports.
              {/* <br />
              <br />
              Opened a new location in Suffolk County to better serve Long
              Island residents. <br />
              September 14, 2018 will commemorate the 24th year of Robin A Gray
              Esq., admittance to <br />
              the NY State Bar. */}
            </p>
            <p id="aboutlearnmore">
              <NavLink style={{ color: "inherit" }} to="/about">
                Learn More
              </NavLink>
            </p>
          </div>
        </div>
        <div id="asideoneoutside">
          <p
            style={{
              fontSize: 30,
              width: "80%",
              textShadow:
                "-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white",
              textAlign: "left",
              color: "#343232",
            }}
          ></p>
        </div>
      </section>
      <section id="sectionservices">
        <div id="sectionservicesinside">
          <div className="container">
            <p id="servicesheader">Our Services</p>
            <p id="servicescontent">
              Opened a new location in Suffolk County to better serve Long
              Island residents. <br></br>September 14, 2018 will commemorate the
              24th year of Robin A Gray Esq., admittance to <br />
              the NY State Bar.
              {/* <br />
              <br />
              We specialize in air freight services in and <br /> out of JFK,
              <br />
              however we cover all types of FTL & LTL logistics and trucking
              needs by a team of highly professional staff <br />
              with substantial experience in the transportation industry */}
            </p>
          </div>
        </div>

        <div id="sectionservicesbar1">
          <div className="servicetypebox" id="servicetypebox1">
            <div className="container">
              <p className="servicetypeboxheader">Imports.</p>
              <p className="servicetypeboxcontent">
                Lead attorney at RG Settlement Services. She is a graduate from
                University of Massachusetts, Amherst, MA and Hofstra Law School,
                Hempstead, NY. She has over 24 years of real estate experience
                and is currently
              </p>
            </div>
          </div>
          <div className="servicetypebox" id="servicetypebox2">
            <div className="container">
              <p className="servicetypeboxheader">Exports.</p>
              <p className="servicetypeboxcontent">
                Lead attorney at RG Settlement Services. She is a graduate from
                University of Massachusetts, Amherst, MA and Hofstra Law School,
                Hempstead, NY. She has over 24 years of real estate experience
                and is currently
              </p>
            </div>
          </div>
        </div>
        <div id="sectionservicesbar2">
          <div className="servicetypebox" id="servicetypebox3">
            <div className="container">
              <p className="servicetypeboxheader">Warehouse to Warehouse.</p>
              <p className="servicetypeboxcontent">
                Lead attorney at RG Settlement Services. She is a graduate from
                University of Massachusetts, Amherst, MA and Hofstra Law School,
                Hempstead, NY. She has over 24 years of real estate experience
                and is currently
              </p>
            </div>
          </div>
          <div className="servicetypebox" id="servicetypebox4">
            <div className="container">
              <p className="servicetypeboxheader">Local Movements.</p>
              <p className="servicetypeboxcontent">
                Lead attorney at RG Settlement Services. She is a graduate from
                University of Massachusetts, Amherst, MA and Hofstra Law School,
                Hempstead, NY. She has over 24 years of real estate experience
                and is currently
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="sectionworkwith">
        <div className="container">
          <p id="sectionworkwithheader">Companies we worked with</p>
          <p id="sectionworkwithcontent">
            Opened a new location in Suffolk County to better serve Long Island
            residents.
          </p>
          <div id="companylogos">
            {[
              companyLogo4,
              comapnyLogo2,
              comapnyLogo1,
              comapnyLogo3,
              comapnyLogo2,
              comapnyLogo3,
              companyLogo4,
              comapnyLogo1,
            ].map((data, index) => {
              return <img key={index} src={data} />;
            })}
          </div>
        </div>
      </section>
      <aside id="asidetwo">
        <div id="asidetwoinside"></div>
        <div id="asidetwooutside">
          <p
            style={{
              textAlign: "left",
              width: "80%",
              color: "#343232",
              fontSize: 30,
            }}
          ></p>
        </div>
      </aside>

      <div id="locate">
        <p className="container">Connect With Us</p>
      </div>
      <section id="mapmessagecontainer">
        <section id="messagesection">
          <div className="container">
            <div id="messagesectioncontent">
              {/* <div
          style={{
            fontSize: 30,
            paddingTop: 49,
            paddingLeft: 43,
            textAlign: "left",
          }}
        > */}
              <div id="messagedialogclose">
                {!hideMessageModal && (
                  <GrFormClose
                    onClick={() => {
                      setHideMessageModal(true);
                      document.getElementById("messagesection").style.position =
                        "relative";
                      document.getElementById("messagesection").style.top = "0";
                      document.getElementById("messagesection").style.height =
                        "0";
                      document.getElementById("messagesection").style.overflow =
                        "hidden";
                      document.getElementById("messagesection").style.float =
                        "left";
                      document.getElementById(
                        "messagesection"
                      ).style.marginTop = "91px";
                      document.getElementById(
                        "messagesection"
                      ).style.marginRight = "0";
                      document.getElementById("messageform").style.borderWidth =
                        "0";
                      // document.getElementById("messageform").style.paddingTop =
                      //   "0";
                      document.getElementById("messagesection").style.width =
                        "100%";
                      document.getElementById("messageform").style.paddingLeft =
                        "0";
                    }}
                    color="#4a4a4a"
                  />
                )}
              </div>
              {/* <p
                  id="messageformheader"
                  style={{
                    margin: 0,
                    marginBottom: 48,
                    // fontFamily: "utopia-std-headline, serif",
                    // fontStyle: "normal",
                    // fontWeight: "bold",
                  }}
                >
                  Leave a Message
                </p> */}
              <MessageForm
                Loading={Loading}
                setLoading={(val) => setLoading(val)}
              />
            </div>
          </div>
        </section>
        <div id="map">
          <CustomMap google={props.google} />
        </div>
      </section>

      <CornerDialog
        width={windowSize > 668 ? 505 : "98vw"}
        containerProps={{
          zIndex: 10,
          backgroundColor: "rgba(0,0,0,0.90)",
          borderRadius: 0,
          right: 5,
          bottom: 5,
        }}
        isShown={true}
        hasCancel={false}
        hasFooter={false}
      >
        <p style={{ color: "white", lineHeight: 1.5 }}>
          Shares of Workhorse Group Inc., a maker of electric delivery vehicles,
          are surging as investors bet the Trump administration will want the
          company and a fellow Ohio-based affiliate to succeed.
        </p>
        <br></br>

        <NavLink
          to="/news"
          style={{ color: colors.primary, float: "right", marginRight: 19 }}
        >
          Learn More
        </NavLink>
        <br></br>
        <br></br>
      </CornerDialog>
      <Footer />
    </React.Fragment>
  );
};

export default GoogleApiWrapper({
  // apiKey: "AIzaSyCphsGKM-aoSIo2aF5Q_ysYplj7oNz6IxY",
  apiKey: "AIzaSyBtGO4t06E4FtrngqZNVQi8YYazGx9SMxI",
})(Home);
