import React, { Component } from "react";

import Header from "../Header";
import Footer from "../Footer";

import img1 from "../../assets/images/aboutimage1.jpeg";
import img2 from "../../assets/images/aboutimage2.jpeg";
import img3 from "../../assets/images/aboutimage3.jpg";
import img4 from "../../assets/images/aboutimage4.jpg";

class About extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Header></Header>
        <div id="aboutcontainer" className="container">
          <section id="intro1">
            <h3>Who are we and what we do</h3>
            <p>
              Freightage is a limited liability provides top-notch freight and
              transportation solution. Is a TSA approved based in New York and
              have substantial operating capacity in the Tri-State on major
              airports (JFK, Newark and La Guardia).
            </p>
          </section>
          <section id="img1">
            <img width={"100%"} src={img1}></img>
          </section>
          <section id="intro2">
            <aside id="intro2left">
              <div id="img2back"></div>
              <img id="img2" src={img2}></img>
            </aside>
            <aside>
              <p>
                Powered by a team of highly professional staff with substantial
                experience in the transportation industry. We specialize in air
                freight services in and out of JFK, however we cover all types
                of FTL & LTL logistics and trucking needs.
              </p>
              <p>
                At Freightage our competitive landscape is changing. With
                technology expanding and converging we have state of the art
                logistical tracking system, giving full authority of engagement
                to our customers.
              </p>
            </aside>
          </section>
          <section id="heading2">
            <h1>
              Our work is recognized in jfk <br></br>trucking business.
            </h1>
          </section>
          <aside id="img3">
            <img width={892} src={img3}></img>
          </aside>
          <section id="team">
            <h2>OUR TEAM</h2>
            <div id="teamrow">
              {[
                { name: "M. Babar", occupation: "President" },
                { name: "Ch. Sayam", occupation: "President" },
                { name: "Terry", occupation: "Trucker" },
                { name: "Kevin Clein", occupation: "President" },
                { name: "Kevin CLein", occupation: "President" },
                { name: "Kevin CLein", occupation: "Trucker" },
              ].map((data, index) => {
                return (
                  <div className="emloyeebox">
                    <div
                      style={{
                        width: "100%",
                        height: 284,
                        borderBottom: "1px solid #707070",
                      }}
                    ></div>
                    <p>{data.name}</p>
                  </div>
                );
              })}
            </div>
          </section>
        </div>
        <Footer> </Footer>
      </React.Fragment>
    );
  }
}

export default About;
