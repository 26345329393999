import React, { useState, useRef } from "react";
import {
  Map,
  InfoWindow,
  Marker,
  GoogleApiWrapper,
  Polygon,
  Polyline,
} from "google-maps-react";
import customstyles from "../../../assets/map/googlemap";
import { FaDirections } from "react-icons/fa";
import truckImage from "../../../assets/icons/markerMap.png";

function CustomMap(props) {
  const [showInfoWindow, setShowInfoWindow] = useState(null);

  const officeMarker = useRef();

  return (
    <Map
      containerStyle={{
        width: "100%",
        height: "100%",
        position: "relative",
      }}
      zoom={10}
      initialCenter={{
        lat: 40.72,
        lng: -73.61081,
      }}
      google={props.google}
      styles={customstyles}
      zoom={12}
    >
      <Marker
        ref={officeMarker}
        style={{ backgroundColor: "red" }}
        title="Our Office!"
        position={{ lat: 40.66969, lng: -73.75081 }}
        onMarkerClicked={(props, marker) => {
          setShowInfoWindow(marker);
        }}
        onMouseover={(props, marker) => {
          setShowInfoWindow(marker);
        }}
        icon={{
          url: truckImage,
          // scaledSize: new window.google.maps.Size(45, 45),
        }}
      ></Marker>
      <InfoWindow
        position={{ lat: 40.6817, lng: -73.75081 }}
        // marker is used instead of position because other vise modal comes on top of market
        marker={showInfoWindow}
        visible={true}
        overlayClass="inininin"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: 200,
            height: 100,
            justifyContent: "flex-end",
          }}
        >
          <a
            target="_blank"
            href="https://www.google.com/maps/dir//131-14+Springfield+Blvd,+Jamaica,+NY+11413/"
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "flex-end",
              alignItems: "center",
              color: "inherit",
              textDecoration: "none",
              marginTop: 10,
            }}
          >
            <FaDirections size={25} color="rgba(0,0,0,0.8)" />
            <p
              style={{
                lineHeight: 0,
                fontWeight: "bold",
                color: "rgba(0,0,0,0.8)",
              }}
            >
              Get Directions
            </p>
          </a>
        </div>
      </InfoWindow>
    </Map>
  );
}

export default CustomMap;
