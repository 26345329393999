import React, { useState } from "react";
import { useTrail, animated } from "react-spring";

function AnimatedSlideShowHeaderText() {
  const items = [
    "Making Logistics Simplified - Hire a trucking company.",
    // "Handling clients ipsm is the test for default handling of the cleint, in service in stock.",
    "Company of choice for our customers by fostering a profitable, disciplined and safe working environment.",
  ];
  const config = { mass: 5, tension: 2000, friction: 200 };
  const [toggle, set] = useState(true);
  const trail = useTrail(items.length, {
    config,
    opacity: toggle ? 1 : 0,
    x: toggle ? 0 : 100,
    from: { opacity: 0, x: 20 },
  });
  return (
    <>
      {trail.map(({ x, ...rest }, index) =>
        index === 0 ? (
          <animated.div
            key={items[index]}
            className="trails-text"
            style={{
              ...rest,
              transform: x.interpolate((x) => `translate3d(0,${x}px,0)`),
            }}
          >
            <animated.h1>{items[index]}</animated.h1>
          </animated.div>
        ) : (
          <animated.div
            key={items[index]}
            className="trails-text"
            style={{
              ...rest,
              transform: x.interpolate((x) => `translate3d(0,${x}px,0)`),
            }}
          >
            <animated.p>{items[index]}</animated.p>
          </animated.div>
        )
      )}
    </>
  );
}

export default AnimatedSlideShowHeaderText;
